document.querySelector("#footer__copyright").innerHTML =
  new Date().getFullYear();

const openings = {
  PON: "12:00 - 18:00",
  WT: "10:00 - 18:00",
  ŚRO: "10:00 - 18:00",
  CZW: "10:00 - 18:00",
  PT: "10:00 - 18:00",
  SOB: "09:00 - 14:00",
};

Object.entries(openings).forEach((opening) => {
  const day = opening[0];
  const hours = opening[1];

  const listItem = document.createElement("li");
  const listLabel = document.createElement("span");

  listLabel.classList.add("list-item__label");
  listLabel.innerText = day;

  listItem.classList.add("list-item");
  listItem.append(listLabel);
  listItem.append(hours);

  document.querySelector(".contact__hours").append(listItem);
});

const imagesToBlur = document.querySelectorAll(".blur");
imagesToBlur.forEach((imageToBlur) => {
  const source = imageToBlur.src;
  const alt = imageToBlur.alt;

  const mainImage = imageToBlur.cloneNode();
  mainImage.style = "position: relative; z-index: 2";

  const backgroundImage = imageToBlur.cloneNode();
  backgroundImage.style =
    "position: absolute; top: 16px; left: 6px; filter: blur(32px)";

  const finalImage = document.createElement("div");
  finalImage.style = "position: relative; width: 100%";
  finalImage.appendChild(mainImage);
  finalImage.appendChild(backgroundImage);

  imageToBlur.replaceWith(finalImage);
});

const scrollToContact = () => {
  document.querySelector("#contact").scrollIntoView({ behavior: "smooth" });
};
